import 'normalize.css'

import { Link, Typography } from '@klarna/bubble-ui'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import RecaptchaFooter from "../../components/RecaptchaFooter";
import { getCopyrightTextByLocation } from '../../shared/copyrightUtil'
import { findMerchantPortalUrl } from '../../shared/merchantPortalUtil'
import useDesignContext from '../../shared/useDesignContext'

const LinkLegal = ({ children, href }) => (
  <Link
    isUnderlined
    colorToken='colors/text/default'
    textToken='text-style/text/paragraphs/tiny/regular'
    href={href}
  >
    {children}
  </Link>
)
LinkLegal.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
}

const StickyFooter = styled.footer(() => {
  const { pageBackgroundColor } = useDesignContext()
  return css`
    bottom: 0;
    z-index: 2;
    position: sticky;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${pageBackgroundColor};
  `
})

const Footer = ({ message, recaptchaV2Required, recaptchaV3Required }) => {
  const merchantPortalUrl = findMerchantPortalUrl(window.location.href)

  return (
    <StickyFooter>
      <Typography
        textAlignToken='text-alignment/center'
        accessibilityPreset='paragraph'
        textToken='text-style/text/paragraphs/tiny/regular'
      >
        {getCopyrightTextByLocation()}
      </Typography>
      <Typography
        textAlignToken='text-alignment/center'
        accessibilityPreset='paragraph'
        textToken='text-style/text/paragraphs/tiny/regular'
      >
        <LinkLegal href={`${merchantPortalUrl}/terms-and-conditions`}>{message.termsTitle}</LinkLegal>. <LinkLegal href={`${merchantPortalUrl}/privacy-policy`}>{message.privacyPolicy}</LinkLegal>
      </Typography>
      <RecaptchaFooter message={message} recaptchaV2Required={recaptchaV2Required} recaptchaV3Required={recaptchaV3Required}/>
    </StickyFooter>
  )
}

Footer.propTypes = {
  recaptchaV2Required: PropTypes.bool,
  recaptchaV3Required: PropTypes.bool,
  message: PropTypes.shape({
    termsTitle: PropTypes.string,
    privacyPolicy: PropTypes.string
  })
}

export default Footer
