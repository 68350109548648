import { combineReducers } from 'redux'

import client from './client'
import info from './info'
import kuasMid from './kuasMid'
import login from './login'
import otpemail from './login-otp-email'
import otpsms from './login-otp-sms'
import logoutConfirm from './logoutConfirm'
import message from './message'
import oauth from './oauthGrant'
import recaptcha from "./recaptcha";
import samlPost from './samlPost'
import userDetails from './sms-input'
import social from './social'
import totp from './totp'
import url from './url'
import user from './user'

export default combineReducers({
  client,
  info,
  kuasMid,
  login,
  logoutConfirm,
  message,
  oauth,
  samlPost,
  totp,
  url,
  user,
  otpsms,
  otpemail,
  userDetails,
  social,
  recaptcha
})
