import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'

function renderPage (Page) {
  Sentry.init({
    dsn: 'https://ffc3c9289f7c40e888cd4f7793e5fa84@o24547.ingest.sentry.io/4504056336482304'
  })

  const [environment = 'production'] = (window.location.href.match(/(localhost|staging|playground)/) || [])

  Sentry.getCurrentScope().setTag('environment', environment)

  ReactDOM.render(<Page />, document.getElementById('root'))
}

export default renderPage
