import 'normalize.css'

import { DesignVersionProvider, Frame, Link, ParticleDesignVersions, SpacerVertical, Typography } from '@klarna/bubble-ui'
import { withStyleSheetOverride } from '@klarna/higher-order-components'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import EnvironmentBanner from '../../components/EnvironmentBanner'
import Message from '../../components/Message'
import useDesignContext from '../../shared/useDesignContext'
import Footer from './Footer'
import Header from './Header'

const getDefaultStyleSheet = () => {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: `calc(100vh - 110px)`
    },
    section: {
      width: 480
    },
    frame: {
      boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.10)',
      transition: 'all 1s ease'
    }
  }
}

const signUpLink = (environment) => {
  switch (environment) {
    case 'production':
      return 'https://portal.klarna.com/signup'
    case 'staging':
      return 'https://portal.nonprod.klarna.net/signup'
    case 'localhost':
      return 'https://portal.nonprod.klarna.net/signup'
    default:
      return ''
  }
}

const ContainerDiv = styled.div(({ resourcesPath, isLoading }) => {
  const { pageBackgroundColor } = useDesignContext()
  return css`
    background-color: ${pageBackgroundColor};
    background-image: url("${resourcesPath}/img/klarna-bg.png");
    background-repeat: no-repeat;
    background-position: center calc(50% - 130px);
    transition: opacity 1s ease;
    opacity: ${isLoading ? '0' : '1'};
  `
})

const Page = ({messageType, messageSummary, disableMessage, children, styleSheet, environment, message, isLoginPage, recaptchaV3SiteKey, recaptchaV2Required, recaptchaV3Required, withBranding, resourcesPath}) => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldShowSignUpLink = isLoginPage && environment !== 'playground'

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <GoogleReCaptchaProvider
      useEnterprise
      reCaptchaKey={recaptchaV3SiteKey}
    >
    <DesignVersionProvider value={ParticleDesignVersions.PANCAKE_LIGHT}>
      <ContainerDiv
        isLoading={isLoading}
        resourcesPath={resourcesPath}
      >
        <Header />
        <EnvironmentBanner environment={environment} />
        <main style={styleSheet.main}>
          {!disableMessage && <Message type={messageType} summary={messageSummary}/>}

          <div style={{
            alignItems: 'center',
            display: 'flex',
            gap: 140,
            maxWidth: 1200
          }}>
            <section style={styleSheet.section}>
              <Frame
                paddingToken="space.500"
                cornerRadiusToken="corner-radius/m"
                UNSAFE_style={styleSheet.frame}
              >
                {children}
              </Frame>
            </section>
          </div>

          <SpacerVertical spaceToken="space/200"/>
          {shouldShowSignUpLink &&
            <Typography
              textAlignToken='text-alignment/center'
              colorToken='colors/text/neutral'
              textToken='text-style/text/super-paragraphs/body/regular'
              UNSAFE_props={{ 'data-testid': 'signup-link' }}
            >
              {message.newToKlarna} <Link colorToken='colors/text/default' href={`${signUpLink(environment)}`}>{message.getStarted}</Link>
            </Typography>
          }
        </main>

        <Footer message={message} recaptchaV2Required={recaptchaV2Required} recaptchaV3Required={recaptchaV3Required}/>

      </ContainerDiv>
    </DesignVersionProvider>
    </GoogleReCaptchaProvider>
  )
}

Page.propTypes = {
  environment: PropTypes.string,
  resourcesPath: PropTypes.string,
  pageRedirectUri: PropTypes.string,
  messageType: PropTypes.string,
  messageSummary: PropTypes.string,
  disableMessage: PropTypes.bool,
  children: PropTypes.node,
  isLoginPage: PropTypes.bool,
  withBranding: PropTypes.bool,
  recaptchaV3SiteKey: PropTypes.string,
  recaptchaV2Required: PropTypes.bool,
  recaptchaV3Required: PropTypes.bool,
  message: PropTypes.shape({
    newToKlarna: PropTypes.string,
    getStarted: PropTypes.string,
    termsTitle: PropTypes.string,
    privacyPolicy: PropTypes.string
  }),
  styleSheet: PropTypes.shape({
    frame: PropTypes.object,
    main: PropTypes.object,
    section: PropTypes.object
  }).isRequired
}

Page.defaultProps = {
  disableMessage: false,
  isLoginPage: false
}

// Take in a component as argument WrappedComponent
function withEnvironment (WrappedComponent) {
  const [environment = 'production'] = (window.location.href.match(/(localhost|staging|playground)/) || [])

  // And return a new anonymous component
  return class extends React.Component {
    render () {
      return <WrappedComponent environment={environment} {...this.props} />
    }
  }
}

export default R.compose(
  connect(
    (state, ownProps) => ({
      messageType: state.message.type || ownProps.messageType,
      messageSummary: state.message.summary,
      message: state.message,
      recaptchaV3SiteKey: state.recaptcha.recaptchaV3SiteKey,
      recaptchaV2Required: state.recaptcha.recaptchaV2Required,
      recaptchaV3Required: state.recaptcha.recaptchaV3Required,
      resourcesPath: state.url.resourcesPath,
      pageRedirectUri: state.info.pageRedirectUri
    })
  ),
  withEnvironment,
  withStyleSheetOverride(getDefaultStyleSheet)
)(Page)

export { Page }
